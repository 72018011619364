a{
    position: relative;
    text-decoration: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    letter-spacing: 0.5px;
    display: flex;
    
}
a:focus{
    outline: none;
}
.underline:after{
    content : "";
    position: absolute;
    background-color: #F95738;
    height: 3px;
    width: 0;
    left: 0;
    margin-top: 24px;
}
.underline:hover:after{
    transition: all;
    transition-duration: 0.5s;
    width: 100%;
}
nav {
    height: 64px;
    width:100%;
    min-width: 600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    
 }
@media (max-width:800px) {
    nav {
        height: 64px;
        width:100%;
        min-width: 400px;
        display: flex;
        justify-content: center;
        gap: 12px;
      }
}
